<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link
            :to="{ name: `${user ? user.role : 'admin'}.dashboard`}"
            class="logo"
          >
            <div class="iq-light-logo">
              <img
                src="@/assets/images/logo.gif"
                class="img-fluid"
                alt="logo"
              >
            </div>
            <div class="iq-dark-logo">
              <img
                src="@/assets/images/logo-dark.gif"
                class="img-fluid"
                alt="logo"
              >
            </div>
            <span>{{ appName }}</span>
          </router-link>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <!-- <div class="navbar-left">
          <ul
            id="topbar-data-icon"
            class="d-flex p-0 topbar-menu-icon"
          >
            <li
              v-for="(item, index) in bookmark"
              :key="index"
            >
              <router-link
                :to="item.link"
                class="nav-item"
                style="color: var(--iq-primary)"
              >
                <i :class="`${item.icon}`" />
              </router-link>
            </li>
          </ul>
          <div class="iq-search-bar d-none d-md-block">
            <form
              action="#"
              class="searchbox"
            >
              <input
                v-model="globalSearch"
                type="text"
                class="text search-input"
                style="cursor: pointer"
                placeholder="Type here to search..."
                @click="openSearch"
              >
              <a
                class="search-link"
                href="#"
              ><i class="ri-search-line" /></a>
              <GlobalSearch
                :search="globalSearch"
                @closeSearch="removeClass "
              />
            </form>
          </div>
        </div> -->
        <b-navbar-toggle
          target="nav-collapse"
          class=""
        >
          <i class="ri-menu-3-line" />
        </b-navbar-toggle>
        <div
          v-if="$route.meta.layout !== 'two-sidebar'"
          class="iq-menu-bt align-self-center"
        >
          <div
            class="wrapper-menu"
            @click="sidebarMini"
          >
            <div class="main-circle">
              <i class="ri-arrow-left-s-line" /></div>
            <div class="hover-circle">
              <i class="ri-arrow-right-s-line" /></div>
          </div>
        </div>
        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <ul class="navbar-nav ml-auto navbar-list">
            <!-- <li
              v-nav-toggle
              class="nav-item"
            >
              <a
                class="search-toggle iq-waves-effect language-title"
                href="#"
              ><img
                :src="selectedLang.image"
                alt="img-flaf"
                class="img-fluid mr-1"
                style="height: 16px; width: 16px;"
              ><i class="ri-arrow-down-s-line" /></a>
              <div class="iq-sub-dropdown">
                <a
                  v-for="(lang, i) in langsOptions"
                  :key="`Lang${i}`"
                  class="iq-sub-card"
                  href="javascript:void(0)"
                  @click="langChange(lang)"
                >
                  <img
                    :src="lang.image"
                    alt="img-flaf"
                    class="img-fluid mr-2"
                  >{{ lang.title }}
                </a>
              </div>
            </li>
            <li
              v-nav-toggle
              class="nav-item"
            >
              <div class="iq-sub-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white">
                        {{ $t('nav.allCarts') }}
                      </h5>
                    </div>
                    <div
                      v-for="(item, index) in cartItems"
                      :key="index"
                      class="iq-sub-card"
                    >
                      <div class="media align-items-center">
                        <div class="w-20 text-center d-none justify-content-center d-md-block">
                          <img
                            :src="item.image"
                            :alt="item.name"
                            class="w-100 align-self-center mr-3"
                          >
                        </div>
                        <div class="media-body ml-3">
                          <b-button
                            variant=" iq-bg-danger mt-3"
                            size="sm"
                            class="float-right"
                            @click="removeToCart(item)"
                          >
                            X
                          </b-button>
                          <h6 class="mb-0 ">
                            {{ item.name.substring(0,20) + '...' }}
                          </h6>
                          <p class="mb-0 font-size-12">
                            {{ item.description.substring(0,20) + '...' }}
                          </p>
                          <p class="mb-0">
                            <b>$ {{ item.price }}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <router-link :to="{ name: 'app.e-commerce.cart'}">
                      <b-button
                        variant=" iq-bg-primary"
                        block
                      >
                        <i class="fas fa-cart-plus" />{{ $t('nav.viewCarts') }}
                      </b-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-nav-toggle
              class="nav-item"
            >
              <a
                href="#"
                class="search-toggle iq-waves-effect"
              >
                <lottie
                  id="lottie-beil"
                  :option="require('@/assets/images/small/lottie-bell')"
                />
                <span class="bg-danger dots" />
              </a>
              <div class="iq-sub-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white">
                        {{ $t('nav.allNotifications') }}<small class="badge  badge-light float-right pt-1">4</small>
                      </h5>
                    </div>
                    <a
                      v-for="(item, index) in notification"
                      :key="index"
                      href="#"
                      class="iq-sub-card"
                    >
                      <div class="media align-items-center">
                        <div class="">
                          <img
                            class="avatar-40 rounded"
                            :src="item.image"
                            alt="img"
                          >
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ item.name }}</h6>
                          <small class="float-right font-size-12">{{ item.date }}</small>
                          <p class="mb-0">{{ item.description }}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-nav-toggle
              class="nav-item"
            >
              <a
                href="javascript:void(0)"
                class="iq-waves-effect search-toggle"
              >
                <i class="ri-shopping-cart-2-line" />
                <span class="menu-tag ml-2">{{ cartCount }}</span>
              </a>
              <div class="iq-sub-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white">
                        {{ $t('nav.allCarts') }}
                      </h5>
                    </div>
                    <div
                      v-for="(item, index) in cartItems"
                      :key="index"
                      class="iq-sub-card"
                    >
                      <div class="media align-items-center">
                        <div class="w-20 text-center d-none justify-content-center d-md-block">
                          <img
                            :src="item.image"
                            :alt="item.name"
                            class="w-100 align-self-center mr-3"
                          >
                        </div>
                        <div class="media-body ml-3">
                          <b-button
                            variant=" iq-bg-danger mt-3"
                            size="sm"
                            class="float-right"
                            @click="removeToCart(item)"
                          >
                            X
                          </b-button>
                          <h6 class="mb-0 ">
                            {{ item.name.substring(0,20) + '...' }}
                          </h6>
                          <p class="mb-0 font-size-12">
                            {{ item.description.substring(0,20) + '...' }}
                          </p>
                          <p class="mb-0">
                            <b>$ {{ item.price }}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <router-link :to="{ name: 'app.e-commerce.cart'}">
                      <b-button
                        variant=" iq-bg-primary"
                        block
                      >
                        <i class="fas fa-cart-plus" />{{ $t('nav.viewCarts') }}
                      </b-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </li> -->
          </ul>
        </b-collapse>
        <ul class="navbar-list">
          <li
            v-nav-toggle
            class="rounded"
          >
            <a
              href="#"
              class="search-toggle iq-waves-effect d-flex align-items-center bg-success rounded"
            >
              <img
                src="@/assets/images/logo-clean.jpg"
                class="img-fluid rounded mr-3"
                alt="user"
              >
              <div class="caption">
                <h6 class="mb-0 line-height text-white">{{ user ? user.name : null }}</h6>
                <span class="font-size-12 text-white">{{ user ? user.role : null }}</span>
              </div>
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-success p-3">
                    <h5 class="mb-0 text-white line-height">
                      Hello {{ user ? user.name : null }}
                    </h5>
                    <span class="text-white font-size-12">&nbsp;</span>
                  </div>

                  <router-link
                    :to="{name: 'shared.change-password'}"
                    class="iq-sub-card iq-bg-success-hover"
                  >
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-success">
                        <i class="ri-account-box-line" />
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">
                          Change Password
                        </h6>
                        <!-- <p class="mb-0 font-size-12"></p> -->
                      </div>
                    </div>
                  </router-link>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a
                      class="btn btn-success dark-btn-success"
                      href="javascript:void(0)"
                      role="button"
                      @click="logout"
                    >Log Out<i class="ri-login-box-line ml-2" /></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import GlobalSearch from '../../search/GlobalSearch'
import { core, APPNAME } from '@/config/pluginInit'
// import Lottie from '@/components/core/lottie/Lottie'
import { SharedAuthService } from '@/services'

export default {
  name: 'HeaderStyle',
  components: {
    // HoverMenu,
    // GlobalSearch,
    // CollapseMenu
    // Lottie
  },
  data () {
    return {
      appName: APPNAME,
      globalSearch: '',
      notification: [],
      message: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  mounted () {
    if (!this.horizontal) {
      document.addEventListener('click', this.closeSearch, true)
    }
  },
  methods: {
    sidebarMini () {
      core.triggerSet()
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = 'iq-show'
      this.globalSearch = ''
      if (document.getElementById('searchbox-datalink') !== null) {
        document.getElementById('searchbox-datalink').classList.add('show-data')
      }
    },
    closeSearch (event) {
      const classList = event.target.classList
      if (!classList.contains('searchbox') && !classList.contains('search-input')) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ''
      this.globalSearch = ''
      if (document.getElementById('searchbox-datalink') !== null && document.getElementById('searchbox-datalink') !== undefined) {
        document.getElementById('searchbox-datalink').classList.remove('show-data')
      }
    },
    async logout () {
      await SharedAuthService.Logout().then(() => {
        this.$store.dispatch('auth/logout')
        this.$router.replace({ name: 'shared.login' })
      })
    },
    ...mapActions({
      rtlAdd: 'setting/setRtlAction',
      rtlRemove: 'setting/removeRtlAction'
    })
  }
}
</script>
