const LogisticsOfficerSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Pending Reservations',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.pending-reservations'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-text-line'
  },
  {
    title: 'Schedules',
    name: 'sidebar.schedules',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-2-line',
    children: [
      {
        title: 'Vehicles',
        is_active: false,
        link: {
          name: 'logistics-officer.vehicle-schedules'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-car-line'
      },
      {
        title: 'Drivers',
        is_active: false,
        link: {
          name: 'logistics-officer.driver-schedules'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      }
    ]
  },
  {
    title: 'Maintenance',
    name: 'sidebar.maintenance',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Companies',
        is_active: false,
        link: {
          name: 'logistics-officer.companies'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Organizations',
        is_active: false,
        link: {
          name: 'logistics-officer.organizations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Vehicle Types',
        is_active: false,
        link: {
          name: 'logistics-officer.vehicle-types'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-car-line'
      },
      {
        title: 'Vehicles',
        is_active: false,
        link: {
          name: 'logistics-officer.vehicles'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-car-line'
      },
      {
        title: 'Drivers',
        is_active: false,
        link: {
          name: 'logistics-officer.drivers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Transaction Histories',
        is_active: false,
        link: {
          name: 'logistics-officer.transaction-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Vehicle Schedule Histories',
        is_active: false,
        link: {
          name: 'logistics-officer.vehicle-schedule-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Driver Schedule Histories',
        is_active: false,
        link: {
          name: 'logistics-officer.driver-schedule-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default LogisticsOfficerSideMenu
